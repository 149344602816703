var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CreditCheck bg-white column" },
    [
      _c(
        "div",
        { staticClass: "row row--align-center row--justify-between" },
        [
          _c("label", { staticClass: "CreditCheck__header fs-24" }, [
            _vm._v("Run a Credit Check")
          ]),
          !_vm.userIsClient
            ? _c(
                "base-button",
                {
                  staticClass: "bg-blue fc-white",
                  on: { click: _vm.newDebtor }
                },
                [
                  _c("i", { staticClass: "fa fa-add-circle" }),
                  _vm._v("\n      New Debtor\n    ")
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "CreditCheck__top-wrapper" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "CreditCheck__search-wrapper mt-24" },
          [
            _c("base-debtor-search", {
              staticClass: "CreditCheck__debtor-input",
              attrs: {
                label: true,
                "label-for": "debtor",
                placeholder: "Company name or MC ID",
                statuses: ["active", "sandbox"]
              },
              on: { selected: _vm.selectDebtor }
            })
          ],
          1
        )
      ]),
      _c(
        "transition",
        { attrs: { name: "Transition__fade" } },
        [
          _vm.debtor
            ? _c("base-credit-check-item", { attrs: { debtor: _vm.debtor } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "CreditCheck__info-wrapper column" }, [
      _c("label", { staticClass: "fs-20" }, [
        _vm._v("Why run a Credit Check?")
      ]),
      _c(
        "label",
        { staticClass: "CreditCheck__info-subheading fc-light fs-14 mt-10" },
        [
          _vm._v(
            "\n        If your provider’s credit isn’t high enough,\n        we might not be able to advance your invoice\n      "
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }