<template>
  <div class="CreditCheck bg-white column">
    <div class="row row--align-center row--justify-between">
      <label class="CreditCheck__header fs-24">Run a Credit Check</label>
      <base-button
        v-if="!userIsClient"
        @click="newDebtor"
        class="bg-blue fc-white"
      >
        <i class="fa fa-add-circle" />
        New Debtor
      </base-button>
    </div>
    <div class="CreditCheck__top-wrapper">
      <div class="CreditCheck__info-wrapper column">
        <label class="fs-20">Why run a Credit Check?</label>
        <label class="CreditCheck__info-subheading fc-light fs-14 mt-10">
          If your provider’s credit isn’t high enough,
          we might not be able to advance your invoice
        </label>
      </div>
      <div class="CreditCheck__search-wrapper mt-24">
        <base-debtor-search
          @selected="selectDebtor"
          class="CreditCheck__debtor-input"
          :label="true"
          :label-for="'debtor'"
          :placeholder="'Company name or MC ID'"
          :statuses="['active', 'sandbox']"
        />
      </div>
    </div>
    <transition name="Transition__fade">
      <base-credit-check-item
        v-if="debtor"
        :debtor="debtor"
      />
    </transition>
  </div>
</template>

<script>
// Components
import BaseButton from '../components/base-button.vue'
import BaseCreditCheckItem from '../components/base-credit-check-item.vue'
import BaseDebtorSearch from '../components/base-debtor-search.vue'

export default {
  name: 'CreditCheck',

  components: {
    BaseButton,
    BaseCreditCheckItem,
    BaseDebtorSearch,
  },

  props: {
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      debtor: null,
      page: 0
    }
  },

  methods: {
    newDebtor () {
      this.$router.push({ name: 'create-debtor' })
    },

    selectDebtor (debtor) {
      if (debtor) this.debtor = debtor
    }
  }
};
</script>

<style lang="sass">
.CreditCheck
  border-radius: $border-radius
  box-shadow: $container-shadow
  padding-bottom: rem(62px)
  padding-left: rem(29px)
  padding-right: rem(29px)
  padding-top: rem(38px)
  width: 100%

  .BaseSearchInput
    margin: 0 0 rem(24px)
    width: 100%

  .BaseUserSearch
    max-width: unset

  &__debtor-input
    width: 100%

  &__info-subheading
    width: 65%

  &__info-wrapper
    background-color: #FBFBFD
    border: $border
    border-radius: $border-radius
    box-shadow: $shadow-b
    padding: rem(33px) rem(35px)
    width: 100%

  &__search-wrapper
    margin-bottom: rem(44px)
    width: 100%

  &__top-wrapper
    margin-top: rem(33px)
    width: 50%

  @media #{$tablet-landscape-and-down}
    border-radius: 0
    box-shadow: none
    margin-right: 0
    padding: rem(15px) 1rem

    &__header
      display: none

    &__info-subheading
      width: 100%

    &__info-wrapper
      padding: 2rem

    &__search-wrapper
      margin-bottom: 1.5rem

    &__top-wrapper
      width: 100%
</style>
